<template>
  <div :class="{ disabled: disabled }" class="input-search__wrapper">
    <label class="input-search" :class="{ active: isActive }">
      <input
        id="input-search"
        v-model="valueModel"
        :disabled="disabled"
        :placeholder="placeholderValue"
        :class="{ active: isActive, disabled: disabled }"
        autocomplete="off"
        @focus="isActive = true"
        @blur="isActive = false"
        @input="onInput"
      />
      <svg-icon name="icon_search" />
    </label>
  </div>
</template>

<script>
import router from '@/router';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholderValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onInput(event) {
      this.isVisible = true;
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="sass" scoped>
.input-search
  width: 100%
  display: block
  position: relative
  padding: 12px 18px
  border: 1px solid #E1E1E1
  font-size: 12px
  color: #AAAAAA
  height: 4.4rem
  z-index: 100

.disabled
  opacity: 0.8

input
  line-height: 1.8rem !important

.input-search svg
  width: 15px
  height: 15px
  position: absolute
  top: 50%
  right: 15px
  transform: translateY(-50%)

.input-search.active
  border: 1px solid #171717

.dropdown-wrapper
  position: relative

.dropdown-popover
  display: table
  z-index: 99
  position: absolute
  border: 1px solid var(--color-grey)
  max-width: 40rem
  padding: 2.4rem
  top: 6rem
  // left: 0
  // right: 0
  background-color: var(--color-white)


.dropdown-popover__item
  margin: 1.2rem 0
  line-height: 1.4rem
  font-size: 1.2rem
  cursor: pointer

.options
  margin-bottom: 3rem

.popular__title
  font-weight: 500

.popular-buttons__block
  margin-top: 1rem
  display: flex
  gap: 1.2rem
  flex-wrap: wrap
// .dropdown-wrapper .dropdown-popover
//   width: 100%
//   height: 30px
//   border: 1px solid var(--color-grey)
//   padding-left: 0px

// .dropdown-wrapper .dropdown-popover .options
//   width: 100%
//   border: 1px solid var(--color-grey)
//   background-color: var(--color-white)
//   border-radius: 0 0 4px 4px

// .dropdown-wrapper .dropdown-popover .options ul
//   list-style: none
//   text-align: left
//   max-height: 180px
//   overflow-y: scroll
//   overflow-x: hidden
//   font-size: 12px
// .dropdown-wrapper .dropdown-popover .options ul::-webkit-scrollbar
//   display: none

// .dropdown-wrapper .dropdown-popover .options ul li
//   display: flex
//   width: 100%
//   font-size: 12px !important
//   line-height: 18px
//   padding: 6px 18px
//   cursor: pointer

// .dropdown-wrapper .dropdown-popover .options ul li:hover
//   background-color: var(--color-grey)
</style>
