<template>
  <div class="referral">
    <div class="referral__header">
      <div class="referral__header-title">
        <h3>Referral system</h3>
      </div>
    </div>
    <div class="referral__center-block">
      <div class="referral__center-block-title">
        <h4>Referral system</h4>
      </div>
      <div class="input__blocks">
        <div class="referal-id__block">
          <div
            class="referal-id__input"
            @click="copyURL(getProfile.referral_code)"
          >
            <LabelField id="refferal-id" title="Referral ID" />
            <TextField
              id="refferal-id"
              v-model.trim="getProfile.referral_code"
              name="refferal-id"
              is-disabled
              type="text"
              tooltip-text="Invite partners using this code and receive a monthly profit share"
              :error="false"
              error-txt="Error"
            />
          </div>
        </div>
        <div class="referal-link__block">
          <div
            class="referal-link__input"
            @click="copyURL(getProfile.referral_link)"
          >
            <LabelField id="refferal-link" title="Referral link" />
            <TextField
              id="refferal-link"
              v-model.trim="getProfile.referral_link"
              tooltip-text="Invite partners via the link and receive a monthly profit share"
              name="refferal-link"
              type="text"
              is-disabled
              :error="false"
              error-txt="Error"
            />
          </div>
        </div>
        <div class="percentage-card__block">
          <div
            v-for="(item, id) in getDashboardSettings.referral_percentage"
            :key="id"
            class="percentage-card__item"
          >
            <svg-icon
              v-if="item.from == '0'"
              name="arrow_left_small"
            ></svg-icon>
            <svg-icon v-if="item.to == '0'" name="arrow_right_small"></svg-icon>
            <h5 v-if="item.from != '0'">{{ item.from_formatted }}</h5>
            <span v-if="item.from != '0' && item.to != '0'">-</span>
            <h5 v-if="item.to != '0'">{{ item.to_formatted }}</h5>
            <h5>&nbsp;- {{ item.percent }}%</h5>
          </div>
        </div>
      </div>
    </div>
    <ReferralTable :referral="getDashboard.freeze" />
  </div>
</template>

<script>
import LabelField from '@/elements/LabelField';
import { mapGetters } from 'vuex';
import ReferralTable from '@/elements/ReferralTable.vue';

export default {
  components: { LabelField, ReferralTable },
  data() {
    return {
      enable2FALoader: false,
      disable2FALoader: false,
      oldPassword: '',
      password: '',
      confirmPassword: '',
      confirmLoader: false,
      referalID: 'Gh22J8',
      referalLink: 'https://vyron.io/referral',
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getErrors',
      'getDashboard',
      'getDashboardSettings',
    ]),
  },
  async created() {
    await this.$store.dispatch('getProfile');
    await this.$store.dispatch('getDashboardSettings');
  },
  methods: {
    async copyURL(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$store.dispatch('setNotification', {
          type: 'success',
          title: `Copied to clipboard`,
          text: ``,
        });
      } catch ($e) {
        alert('Cannot copy');
      }
    },
    resetPasswords() {
      this.oldPassword = '';
      this.password = '';
      this.confirmPassword = '';
    },
    async saveNewPassword() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.confirmLoader = true;
      const res = await this.$store.dispatch('changePassword', {
        current_password: this.oldPassword,
        new_password: this.password,
        new_password_confirmation: this.confirmPassword,
      });
      this.confirmLoader = false;

      if (res) {
        this.hideValidationErrors();
        this.resetPasswords();
      }
    },
    async enable2FA() {
      this.enable2FALoader = true;
      await this.$store.dispatch('getTwoFactorSecret');
      this.enable2FALoader = false;
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'two-factor',
      });
    },
    async disable2FA() {
      this.disable2FALoader = true;
      await this.$store.dispatch('sendEmailVerificationCode');
      this.disable2FALoader = false;
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'two-factor-disable',
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.referral
  padding: 2.4rem
  display: flex
  flex-direction: column
  gap: 2.4rem
  flex: 0 0 100%
  height: 100%

.referral__header
  display: flex
  justify-content: space-between
  align-items: center
  // @include m
  //   flex-direction: column

.referral__header-buttons
  display: flex
  justify-content: space-between
  gap: 2.4rem

.referral__header-title
  display: flex
  justify-content: space-between

.referral__top-block, .referral__center-block, .referral__bottom-block
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 2.4rem
  padding: 2.4rem
  &-button
    margin-left: auto

.referral__top-block-password
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include l
    flex-direction: column
    max-width: 44rem
    margin: 0 auto
    width: 100%
    // justify-content: center
    // align-items: center


.password__block
  min-width: 20rem
  flex-grow: 1
  // flex: 0 0 30%

.input__blocks
  display: flex
  gap: 2.4rem
  flex-direction: row
  justify-content: space-between
  flex-wrap: wrap

.referral__center-block-title
  display: flex
  align-items: end
  gap: 1.2rem

.referal-id__block, .referal-link__block
  display: flex
  flex-direction: column
  gap: 0.8rem
  flex-grow: 1

.referal-id__header, .referal-link__header
  display: flex
  justify-content: space-between
  align-items: center
  & svg
    height: 1.4rem

.referral__bottom-block-title
  display: flex
  align-items: center
  gap: 1.2rem

.unset__block, .set__block
  display: flex
  align-items: center
  & svg
    height: 1.4rem
    width: 2.8rem

.unset__block
  color: $negative-text

.set__block
  color: $success-text

.download__title
  margin: 0 auto

.download__blocks
  display: flex
  gap: 2.4rem
  justify-content: space-between
  @include m
    flex-wrap: wrap

.download__blocks-google, .download__blocks-apple
  background-color: $background-color
  text-align: center
  padding: 4rem
  flex: 0 1 100%
  cursor: pointer

.google__icon, .apple__icon
  margin-bottom: 2.4rem
  max-height: 5rem
  max-width: 5rem

.download__blocks-google-subtitle, .download__blocks-apple-subtitle
  margin-bottom: 0.8rem

.enable-2fa__button
  margin-left: auto

.info-circle
  cursor: pointer

.percentage-card__block
  display: flex
  gap: 2.4rem
  flex-wrap: wrap
  padding-top: 2.7rem
  @include l
    flex: 1 0 100%
    padding-top: 0


.percentage-card__item
  display: flex
  flex-grow: 1
  padding: 1.3rem 1.8rem
  background-color: $background-color
  justify-content: start
  align-items: center
  white-space: nowrap
  @include m
    width: 100%

  & svg
    max-height: 1.2rem
    max-width: 0.8rem
    margin-right: 0.5rem
</style>
