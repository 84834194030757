<template>
  <div v-if="dashboardLoaded" class="referral-card">
    <div class="referral-card__header">
      <div class="referral-card__header-left">
        <h4 class="referral-card__title">Referral income table</h4>
        <div class="money-card__numbers">
          <h2 class="money-card__numbers-main">
            {{ getDashboard.referral_income_chart.amount }}
          </h2>
        </div>
        <p class="subtitle">By referral link</p>
      </div>
      <div class="referral__search">
        <InputSearch
          id="search"
          :disabled="referrals.length === 0"
          name="search"
          placeholder-value="Search"
          search
          :value="searchInputValue"
          tooltip-text=""
          @input="setSearchInput"
        />
      </div>
      <div class="referral__select">
        <SelectField
          :is-disabled="referrals.length === 0"
          :value="selectedMonth"
          required
          :options="monthOptions"
          @select-value="selectValue"
        />
      </div>
    </div>
    <div class="referral-table">
      <div class="referral-table__header">
        <h5 class="subtitle">Name</h5>
        <h5 class="subtitle">Member since</h5>
        <h5 class="subtitle">Email</h5>
        <h5 class="subtitle">Invested amount</h5>
        <h5 class="subtitle">Amount</h5>
      </div>
      <div v-if="referrals.length !== 0" class="referral-table__items">
        <div
          v-for="(item, id) in referrals"
          :key="id"
          class="referral-table__item"
        >
          <h5>
            {{ item.full_name }}
          </h5>
          <h5>
            <span class="hidden-title">Member since </span
            >{{ item.member_since }}
          </h5>
          <h5><span class="hidden-title">Email </span>{{ item.email }}</h5>
          <h5>
            <span class="hidden-title">Invested amount </span
            >{{ item.invested_amount }}
          </h5>
          <h5 class="referral-amount">
            <span class="hidden-title">Amount </span>{{ item.amount }}
          </h5>
        </div>
      </div>
      <div v-else class="referral-table__empty-state">
        <h5 class="empty-state__title">There is nothing here</h5>
        <h5 class="subtitle">Send a referral link to your friends</h5>
      </div>
    </div>
  </div>
</template>

<script>
import SelectField from '@/elements/SelectField';
import InputSearch from '@/elements/InputSearch.vue';
import { mapGetters } from 'vuex';

export default {
  components: { SelectField, InputSearch },
  props: {},
  data() {
    return {
      searchInputValue: '',
      selectedMonth: { title: 'All referral income', value: false },
      monthOptions: [{ title: 'All referral income', value: false }],
      referrals: {},
      dashboardLoaded: false,
    };
  },
  computed: {
    ...mapGetters(['getDashboard']),
  },
  watch: {
    searchInputValue() {
      this.setSearchQuery();
    },
  },
  async created() {
    await this.$store.dispatch('getDashboard');
    const monthObject = await this.$store.dispatch('getReferralsMonth');
    for (const key in monthObject) {
      if (this.getModalProps === monthObject[key]) {
        this.selectedMonth = {
          title: monthObject[key],
          value: key,
        };
      }
      this.monthOptions.push({
        title: monthObject[key],
        value: key,
      });
    }
    let payload = {
      month: this.selectedMonth.value,
      query: this.searchInputValue,
    };
    this.referrals = await this.$store.dispatch('getReferralsUsers', payload);
    this.dashboardLoaded = true;
  },
  methods: {
    async selectValue(item) {
      this.selectedMonth = item;
      let payload = {
        month: this.selectedMonth.value,
        query: this.searchInputValue,
      };
      this.referrals = await this.$store.dispatch('getReferralsUsers', payload);
    },
    async setSearchQuery() {
      if (this.searchInputValue) {
        let payload = {
          month: this.selectedMonth.value,
          query: this.searchInputValue,
        };
        this.referrals = await this.$store.dispatch(
          'getReferralsUsers',
          payload
        );
      } else {
        let payload = {
          month: this.selectedMonth.value,
          query: this.searchInputValue,
        };
        this.referrals = await this.$store.dispatch(
          'getReferralsUsers',
          payload
        );
      }
    },
    setSearchInput(input) {
      this.searchInputValue = input;
    },
  },
};
</script>

<style lang="sass" scoped>
.referral-card
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 0.8rem
  padding: 2.4rem
  flex-grow: 1
  // border-top: 0.5rem solid $primary-color-blue

.referral-card__header-left
  display: flex
  gap: 0.8rem
  flex-direction: column

.referral-card__header
  display: flex
  justify-content: space-between
  align-items: end
  flex-wrap: wrap
  gap: 2.4rem
  @include m
    flex-direction: column
    align-items: start

.referral-card__bottom
  display: flex
  align-items: center
  gap: 1.8rem

.referral-card__icon
  max-height: 3.4rem
  max-width: 3.4rem

.money-card__numbers
  display: flex
  align-items: center
  gap: 2.4rem

.referral__search
  margin-left: auto
  max-width: 24rem
  width: 100%
  @include m
    // max-width: none
    margin-left: 0
  @include ms
    max-width: none

.referral__select
  @include m
    max-width: 24rem
    width: 100%
  @include ms
    max-width: none

.money-card__numbers-secondary
  display: flex
  align-items: center
  justify-content: center
  border-radius: 0.5rem
  width: 6.5rem
  height: 2.6rem
  background-color: $success-background
  color: $success-text
  & p::after
    content: '%'
    margin-right: 0.4rem
  & svg
    width: 1rem

.arrow__icon
  max-width: 2.4rem

.referral-table
  margin: 1.2rem 0
  flex-grow: 1

.referral-table__header
  display: grid
  grid-template-columns: 2fr 1fr 2fr 2fr 1fr
  // height: 4.8rem
  padding: 1.2rem 2.4rem
  align-items: center
  margin-top: 0.6rem
  gap: 1rem
  @include l
    display: none

.referral-table__item
  display: grid
  grid-template-columns: 2fr 1fr 2fr 2fr 1fr
  // height: 4.8rem
  padding: 1.2rem 2.4rem
  align-items: center
  margin-top: 0.6rem
  gap: 1rem
  @include l
    display: flex
    flex-direction: column
    height: auto
    padding: 2.4rem 2.4rem
    align-items: start
    gap: 1rem
    & :not(:first-child)
      font-size: 1.2rem

.hidden-title
  display: none
  color: $subtitle-color
  @include l
    display: inline

.referral-table__item
  background: $background-color

.referral-table__empty-state
  display: flex
  flex-direction: column
  justify-content: center
  background: $background-color
  height: calc( 100% -  4rem)
  padding: 6rem
  & h5
    // margin: auto auto
    text-align: center

.referral-amount
  color: $primary-color-blue
</style>
